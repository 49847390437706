@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@keyframes animation_growWidth__6HyeV {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.animation_bar__8Tb9o {
  animation: animation_growWidth__6HyeV 0.5s ease-out forwards;
}

@keyframes animation_growDown__Cc0Mh {
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
}

.animation_dropDown__Av2LC {
  animation: animation_growDown__Cc0Mh 0.3s ease-out forwards;
}

@keyframes animation_fadeIn__CbrJa {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

.animation_dropDownFade__tB96u {
  opacity: 0;
  animation: animation_fadeIn__CbrJa 0.2s ease-in 0.2s forwards;
}

@keyframes animation_fadeOut__dg4L0 {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0;
  }
}

.animation_dropDownFadeOut__nJlVm {
  animation: animation_fadeOut__dg4L0 0.1s ease-in forwards;
}

@keyframes animation_growUp__ogaNy {
  from {
    height: 100%;
  }

  to {
    height: 0%;
  }
}

.animation_dropDownExit__8IqyW {
  animation: animation_growUp__ogaNy 0.5s ease-out forwards;
}

.animation_leaderBoardFade__qxoI6 {
  opacity: 0;
  animation: animation_fadeIn__CbrJa 0.3s ease-in forwards;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@keyframes animation_growWidth__vnTtG {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.animation_bar__iVMUS {
  animation: animation_growWidth__vnTtG 0.5s ease-out forwards;
}

@keyframes animation_growDown__hiVq_ {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.animation_dropDown__Fup_7 {
  animation: animation_growDown__hiVq_ 0.3s ease-out forwards;
}

@keyframes animation_fadeIn__nxIZ5 {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

.animation_dropDownFade__4LAZ_ {
  opacity: 0;
  animation: animation_fadeIn__nxIZ5 0.2s ease-in 0.2s forwards;
}
.animation_menuFade__i28wC {
  opacity: 0;
  animation: animation_fadeIn__nxIZ5 0.2s ease-in forwards;
}
.animation_leaderBoardFade__wTmZ3 {
  opacity: 0;
  animation: animation_fadeIn__nxIZ5 0.3s ease-in forwards;
}
.animation_body__rj56i {
  font-family: "Roboto", sans-serif;
}

